<template>
    <div class="message-details">
        <!-- 返回 -->
        <div class="back_btn">
            <div class="icon_btn" @click="backBtn">
                <i class="el-icon-back"></i>
                <span>返回</span>
            </div>
        </div>
        <div class="content">
            <!-- <div class="title">{{ title }}</div> -->
            <div v-html="notice"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            notice: '',
            id: null,
        }
    },
    created() {
        this.id = this.$route.query.id
        if (this.id) {
            this.getDetails()
        }
    },
    methods: {
        // 返回
        backBtn() {
            this.$router.back()
        },
        getDetails() {
            this.$personApi.getMessageDetails(this.id).then(res => {
				if (res.code == 1000) {
					this.notice = res.result.notice
			        this.$store.dispatch('userInfo/getConfig')
				} else {
					this.$errMsg(res.message)
				}
			})
        }
    }
}
</script>
<style lang="scss" scoped>
.message-details {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;

    .back_btn {
        width: 100%;
        padding: 18px 20px;
        border-bottom: 1px solid #EDEDED;
        display: flex;
        align-items: center;

        .icon_btn {
            display: flex;
            align-items: center;
        }

        .el-icon-back {
            cursor: pointer;
            font-size: 18px;
            color: #666;
            margin-top: 2px;
        }

        span {
            cursor: pointer;
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }

    .content {
        width: 900px;
        margin: 0 auto;
        min-height: 600px;
        padding: 20px;
    }
}
</style>